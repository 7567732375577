.form-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    max-height: 0;
    transform: scaleY(0) translate(-50%, -50%);
    transition: max-height 0.5s ease, transform 0.5s ease;
    width: 60%;
    background: linear-gradient(135deg, #6dd5ed, #2193b0);
    padding: 30px 20px;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.form-wrapper.open {
    max-height: 600px;
    transform: scaleY(1) translate(-50%, -50%);
}

.form-wrapper.closed {
    transform: scaleY(0) translate(-50%, -50%);
}

.gform {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.formimg img {
    width: 100%;
    border-radius: 8px;
}

.formsae {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.formsae label {
    font-weight: bold;
    color: #333;
    font-size: 0.9rem;
}

.formsae input[type="text"],
.formsae input[type="email"],
.formsae textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-size: 1rem;
}

.formsae input:focus,
.formsae textarea:focus {
    outline: none;
    border-color: #4caf50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

textarea {
    resize: none;
}

.fbtn {
    display: flex;
    justify-content: space-between;
}

.subbtn,
.closef {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.subbtn {
    background-color: #007bff;
    color: white;
}

.subbtn:hover {
    background-color: #0056b3;
}

.closef {
    background-color: #f44336;
    color: white;
}

.closef:hover {
    background-color: #d32f2f;
}

/* Responsive Design */
@media (max-width: 768px) {
    .form-wrapper {
        width: 95%;
    }

    .fbtn {
        flex-direction: column;
        gap: 10px;
    }

    .gform {
        flex-direction: column;
    }

    .formimg img {
        width: 80px;
    }
}