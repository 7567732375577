/* Home.css - Redesigned for improved aesthetics */

.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* Hero Section Styles */
  .hero-section {
    position: relative;
    padding: 3rem 1rem;
    text-align: center;
    margin-bottom: 3rem;
    border-radius: 8px;
    background-color: #f8f9fa;
    overflow: hidden;
  }
  
  .graph-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.1;
    background-image: radial-gradient(#2c3e50 1px, transparent 1px);
    background-size: 20px 20px;
  }
  
  .title {
    position: relative;
    font-size: 2.5rem;
    font-weight: 700;
    color: #2c3e50;
    margin: 0;
    z-index: 1;
  }
  
  /* Content Container */
  .content-container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 2rem;
  }
  
  @media (max-width: 992px) {
    .content-container {
      grid-template-columns: 1fr;
    }
  }
  
  /* Teams Section */
  .teams-section {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 2rem;
  }
  
  .lead-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .teams-container {
    display: grid;
    gap: 1.5rem;
  }
  
  .team-card {
    background-color: #f8f9fa;
    border-left: 4px solid #3498db;
    border-radius: 4px;
    padding: 1.5rem;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .team-card:nth-child(2) {
    border-left-color: #e74c3c;
  }
  
  .team-card:nth-child(3) {
    border-left-color: #2ecc71;
  }
  
  .team-card:nth-child(4) {
    border-left-color: #f39c12;
  }
  
  .team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .team-name {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 0.75rem;
    color: #2c3e50;
  }
  
  .team-description {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    margin: 0;
  }
  
  .conclusion-text {
    font-size: 1.1rem;
    font-style: italic;
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
    color: #555;
  }
  
  /* Organization Info Card */
  .org-info-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 2rem;
    height: fit-content;
  }
  
  .org-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .org-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1.5rem;
  }
  
  .logo-container {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    padding: 1rem;
  }
  
  .org-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .info-grid {
    display: grid;
    row-gap: 1.25rem;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #eee;
  }
  
  .info-icon {
    color: #3498db;
    margin-right: 1rem;
    flex-shrink: 0;
  }
  
  .info-content {
    flex-grow: 1;
  }
  
  .info-label {
    font-size: 0.85rem;
    color: #777;
    margin: 0 0 0.25rem 0;
  }
  
  .info-value {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin: 0;
  }
  
  .info-link {
    font-size: 1rem;
    font-weight: 500;
    color: #3498db;
    text-decoration: none;
  }
  
  .info-link:hover {
    text-decoration: underline;
  }
  
  /* Highlight for search */
  .highlight {
    background-color: #FFA500;
    border-radius: 2px;
    padding: 0 2px;
  }

  @media (max-width: 400px){
    h1.title{
      font-size: 22px;
    }
  }