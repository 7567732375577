/* Base styling */
.history-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Header styling */
.history-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
}

.history-header h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

section{
  display: block;
}

.history-header p {
  font-size: 1rem;
  color: #666;
}

/* Navigation styling */
.timeline-nav {
  display: flex;
  overflow-x: auto;
  gap: 0.5rem;
  margin-bottom: 2rem;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 0.75rem 0;
  z-index: 100;
  border-bottom: 1px solid #eee;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: thin; /* For Firefox */
}

.timeline-nav::-webkit-scrollbar {
  height: 4px;
}

.timeline-nav::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 4px;
}

.timeline-nav button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  flex-shrink: 0;
  min-width: 70px;
}

.timeline-nav button:hover {
  background-color: #f0f0f0;
}

.timeline-nav button.active {
  background-color: #e6e6e6;
  font-weight: 500;
}

.nav-icon {
  margin-bottom: 0.25rem;
  color: #555;
}

.nav-label {
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

/* Timeline styling */
.timeline {
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  width: 2px;
  background-color: #e0e0e0;
}

.timeline-item {
  position: relative;
  margin-bottom: 3rem;
  opacity: 0.7;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  padding-left: 2.5rem;
}

.timeline-item.visible {
  opacity: 1;
  transform: translateY(0);
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: 0.75rem;
  top: 0.5rem;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #666;
  z-index: 1;
}

.timeline-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.timeline-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0 0.5rem;
}

.timeline-icon {
  background-color: #f5f5f5;
  padding: 0.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  flex-shrink: 0;
}

.timeline-body p {
  margin-bottom: 1rem;
  font-size: 0.95rem;
}

/* Gallery styling */
.timeline-gallery {
  margin-top: 1rem;
  width: 100%;
}

.image-wrapper {
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-wrapper img {
  width: 100%;
  height: auto;
  display: block;
}

/* Highlight styling */
.highlight {
  background-color: rgba(255, 230, 0, 0.3);
  padding: 0 2px;
}

/* Tablet Styles (iPad, etc.) */
@media (min-width: 768px) and (max-width: 1023px) {
  .history-container {
    padding: 1.5rem;
  }
  
  .history-header h1 {
    font-size: 2.25rem;
  }
  
  .timeline-nav {
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: visible;
  }
  
  .timeline-nav button {
    min-width: 90px;
    font-size: 0.8rem;
  }
  
  .timeline::before {
    left: 1.5rem;
  }
  
  .timeline-item {
    padding-left: 3.5rem;
  }
  
  .timeline-item::before {
    left: 1.25rem;
  }
  
  .timeline-header h2 {
    font-size: 1.4rem;
  }
  
  .timeline-body p {
    font-size: 1rem;
  }
  
  /* Two column gallery for tablets */
  .timeline-gallery {
    display: flex;
    gap: 1rem;
  }
  
  .image-wrapper {
    flex: 1;
  }
}

/* Desktop Styles */
@media (min-width: 1024px) {
  .history-container {
    padding: 2rem;
  }
  
  .history-header h1 {
    font-size: 2.5rem;
  }
  
  .history-header p {
    font-size: 1.2rem;
  }
  
  .timeline-nav {
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: visible;
  }
  
  .timeline-nav button {
    min-width: 100px;
    font-size: 0.85rem;
    padding: 0.5rem 1rem;
  }
  
  .timeline::before {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .timeline-item {
    width: 45%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .timeline-item:nth-child(odd) {
    margin-right: auto;
    padding-right: 2.5rem;
  }
  
  .timeline-item:nth-child(even) {
    margin-left: auto;
    padding-left: 2.5rem;
  }
  
  .timeline-item:nth-child(odd)::before {
    left: auto;
    right: -6px;
  }
  
  .timeline-item:nth-child(even)::before {
    left: -6px;
  }
  
  .timeline-item:nth-child(odd) .timeline-header {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  
  .timeline-item:nth-child(odd) .timeline-header h2 {
    margin: 0 0.5rem 0 0;
  }
  
  .timeline-item:nth-child(odd) {
    text-align: right;
  }
  
  .timeline-header h2 {
    font-size: 1.5rem;
  }
  
  .timeline-body p {
    font-size: 1rem;
  }
}

/* Small Phone Specific Adjustments */
@media (max-width: 400px) {
  .history-header h1 {
    font-size: 1.30rem;
  }

  .history-header p {
    font-size: 0.80rem;
  }
  
  .timeline-nav button {
    min-width: 60px;
    padding: 0.4rem;
  }
  
  .nav-label {
    font-size: 0.7rem;
    max-width: 60px;
  }
  
  .timeline-header h2 {
    font-size: 1.1rem;
  }
  
  .timeline-body p {
    font-size: 0.9rem;
  }
  
  .timeline-item {
    padding-left: 2.25rem;
  }
}

/* For devices with hover capability */
@media (hover: hover) {
  .timeline-nav button:hover {
    background-color: #f0f0f0;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .timeline-nav button:active {
    background-color: #e6e6e6;
  }
  
  .timeline-nav {
    padding-bottom: 1rem;
  }
}