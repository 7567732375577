@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .App {
    /* background-color: #02101d; */
    background: radial-gradient(#039fe9 -400%, #000);
    height: 100%;
    color: #fff;
  }
  
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  
  button {
    padding: 0.5rem 2rem;
    background-color: #ffffff20;
    border: 1px solid #ffffff30;
    border-radius: 6px;
  }
  
  button p {
    font-weight: 600;
    font-size: 22px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  
    color: #fff;
  }
  
  button b {
    color: #ae54f4;
    font-weight: 700;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin: auto;
    padding: 5rem 0px 0rem 0px;
    /* background-color: red; */
  }
  
  .secText {
    max-width: 100%;
  }
  
  .mastHead {
    width: 100%;
    text-align: center;
    margin-top: 8em;
    animation: mastHead 0.7s;
    animation-iteration-count: initial;
    top: -16em;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }
  
  .mastHead h1 {
    font-size: 80px;
  }
  
  .mastHead h3 {
    font-size: 18px;
    margin: 0 26em;
    margin-top: 1em;
    font-weight: 400;
    color: #72a7c0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    animation: mastHead 1.5s;
  }
  
  button.lib-btn {
    background: linear-gradient(90deg, #017db7, #4392b7);
    color: white;
    padding: 1.2em 1.5em;
    border-radius: 50px;
    font-size: 16px;
    border: none;
    margin-top: 4em;
    animation: mastHead 2.4s;
  }
  
  button.lib-btn:hover {
    cursor: pointer;
  }
  
  .mastHead h4 {
    margin-top: 2em;
    font-size: 16px;
    color: #686767;
  }
  
  .mastHead h5 {
    color: #686767;
    font-size: 16px;
    animation: scroll 1s;
    animation-iteration-count: infinite;
  }
  @keyframes scroll {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
  }
  
  .campusai{
    width: 90%;
    margin-top: 4em;
    text-align: center;
    margin: 0 5%;
    background:radial-gradient(#1077a730 -500%,#094b6930) ;
    border-radius: 40px 40px 0px 0px;
    padding: 5em 0;
    margin-top: 4em;
    /* padding-bottom: 10em; */
  }
  
  .campusai h1{
    font-size: 50px;
    font-weight: 400;
  }
  
  .campusai h3{
    color: #72a7c0;
    font-weight: 300;
    margin-top: 1em;
  }
  
  .chatSec{
    margin-top: 2em;
  }
  form{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
  label{
    width: 50%;
    text-align: left;
    padding: 0.6em 0.6em;
    font-size: 18px;
    font-weight: 600;
  }
  
  input[type='text']{
    width: 50%;
    padding: 0.6em 1em;
    font-size: 18px;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 2px 10px 0px #00000060;
    margin-bottom: 2em;
  
  }
  
  input[type='text']:focus{
    outline: none;
  }
  
  button[type='submit']{
    padding: 0.6em 1.5em;
    border: 1.5px solid transparent;
    border-radius: 20px;
    font-size: 18px;
    color: aliceblue;
    background: linear-gradient(90deg, #017db7, #4392b7);
    box-shadow: 2px 2px 10px 0px #00000060;
  }
  
  button[type="submit"]:hover{
    background: linear-gradient(90deg, #ffffff, #b9d6e4);
    color: #017db7;
    cursor: pointer;
    border: 1.5px solid #017db7;
  }
  
  .answers{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 30em;
  }
  .answers-content{
    text-align: left;
    width: 85%;
    margin: 2em 0em;
    padding: 2em;
    border: 1px solid #ffffff20;
    border-radius: 10px;
    max-height: 30em;
    overflow-y: scroll;
  }
  
  .contentText{
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
  }
  
  select{
    background-color: transparent;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-right: 2em;
  }
  
  button.copyBtn{
    color: #fff;
    background-color: #039fe9;
    padding: 0.6em 1em;
    margin: 0 0.5em;
    border-radius: 20px;
  }
  
  button.emailBtn{
    color: #00341f;
    background-color: #03e99c;
    padding: 0.6em 1em;
    margin: 0 0.5em;
    border-radius: 20px;
  }
  
  button.emailBtn:hover{
    cursor: pointer;
  }
  
  button.saveBtn{
    color: #004323;
    background-color: #03e967;
    padding: 0.8em 2em;
    margin: 0 0.5em;
    border-radius: 20px;
    font-weight: 500;
  }
  
  button.saveBtn:hover{
    cursor: pointer;
  }
  
  .animate-path {
    stroke-dasharray: 2000; /* Adjust the value based on the path length */
    stroke-dashoffset: 2000;
    animation: dash 3s linear forwards;
    animation-iteration-count: infinite;
  }
  @keyframes mastHead {
    from {
      opacity: 0;
      top: -14em;
    }
    to {
      opacity: 1;
      top: -16em;
    }
  }
  
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  ::-webkit-scrollbar {
    background-color: #464646;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1e1e1e;
  }
  
  @media only screen and (max-width:400px){
    input[type="text"], label{
      width: 75%;
    }
  
    .campusai{
      border-radius: 10px;
      padding-top: 30px;
    }
  }
  
  @media only screen and (max-width:768px){
    input[type="text"], label{
      width: 75%;
    }
  
    .campusai{
      padding-top: 50px;
    }
  }